import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22029e9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page_container_with_tb_padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoDiv = _resolveComponent("VideoDiv")!
  const _component_BookDiv = _resolveComponent("BookDiv")!
  const _component_AudioDiv = _resolveComponent("AudioDiv")!
  const _component_Painter = _resolveComponent("Painter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (item.type == 'video')
          ? (_openBlock(), _createBlock(_component_VideoDiv, {
              key: 0,
              item: item
            }, null, 8, ["item"]))
          : _createCommentVNode("", true),
        (item.type == 'book')
          ? (_openBlock(), _createBlock(_component_BookDiv, {
              key: 1,
              item: item
            }, null, 8, ["item"]))
          : _createCommentVNode("", true),
        (item.type == 'aduio')
          ? (_openBlock(), _createBlock(_component_AudioDiv, {
              key: 2,
              item: item
            }, null, 8, ["item"]))
          : _createCommentVNode("", true),
        (item.type == 'painter')
          ? (_openBlock(), _createBlock(_component_Painter, {
              key: 3,
              item: item
            }, null, 8, ["item"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}