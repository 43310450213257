
import { Options, Vue } from "vue-class-component";
import BoxWithBtn from "@/components/PC/BoxWithBtn.vue";

@Options({
  components: {
    BoxWithBtn,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      isUnfold: false,
    };
  },
  methods: {
    onUnfold(isUnfold: boolean) {
      this.isUnfold = !isUnfold;
    },
  },
})
export default class AudioDiv extends Vue {}
