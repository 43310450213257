
import { Options, Vue } from "vue-class-component";
import BoxWithNews from "@/components/PC/BoxWithNews.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { reactive } from "vue";
import SwiperCore, {
  Autoplay,
  Pagination,
  EffectCoverflow,
  Navigation,
} from "swiper";
SwiperCore.use([Autoplay, Pagination, EffectCoverflow, Navigation]);

const swiper_options = reactive({
  autoplay: {
    disableOnInteraction: false, // 鼠标滑动后继续自动播放
    delay: 4000,
  },

  speed: 500,
  loop: false,
  slidesPerView: "none",
  centeredSlides: false,
  spaceBetween: 20,
  coverflowEffect: {
    stretch: 0,
    depth: 0,
    modifier: 1,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

@Options({
  components: {
    BoxWithNews,
    Swiper,
    SwiperSlide,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      page: 0,
      swiper_options: swiper_options,
    };
  },
})
export default class MediaNews extends Vue {}
