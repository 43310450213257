
import { Options, Vue } from "vue-class-component";
import BoxWithBtn from "@/components/PC/BoxWithBtn.vue";

@Options({
  components: {
    BoxWithBtn,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      data: this.item,
    };
  },
  methods: {
    doPlayVideo() {
      this.showBg = false;
    },
    onDetail(link: string) {
      // window.open(link);
      window.location.href = link;
    },
  },
})
export default class VideoDiv extends Vue {}
