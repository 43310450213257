
import { Options, Vue } from "vue-class-component";
import { kCompanyCulturePageContent } from "@/config/Common/company_culture_data";
import VideoDiv from "./SubDiv/VideoDiv.vue";
import AudioDiv from "./SubDiv/AudioDiv.vue";
import BookDiv from "./SubDiv/BookDiv.vue";
import Painter from "./SubDiv/Painter.vue";

@Options({
  components: {
    VideoDiv,
    AudioDiv,
    BookDiv,
    Painter,
  },
  data() {
    return {
      items: kCompanyCulturePageContent.list,
    };
  },
})
export default class CompanyCulture extends Vue {}
